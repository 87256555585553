import { datadogLogs } from '@datadog/browser-logs'
import type { LogsInitConfiguration } from '@datadog/browser-logs'

export default function useDatadogBrowserLogsClient(
  options: Partial<LogsInitConfiguration> &
    Pick<LogsInitConfiguration, 'service'>
) {
  onBeforeMount(() => {
    if (!import.meta.client) return
    const config = useRuntimeConfig()

    datadogLogs.init({
      clientToken: 'pubc8593913151c3de3f3d832c73b59ffa8',
      site: config.public.datadogSite || 'us3.datadoghq.com',
      env: process.env.NODE_ENV,
      version: config.public.datadogVersion || 'unknown',
      forwardErrorsToLogs: true,
      sessionSampleRate: 0.01,
      ...options,
    })

    datadogLogs.logger.setLevel('warn')
  })
}
