<script setup lang="ts">
  import type { NuxtError } from '#app'

  const props = defineProps<{
    error?: NuxtError
  }>()

  onBeforeMount(() => {
    if (props.error?.statusCode === 404) {
      navigateTo({ path: '/' })
    }
  })
</script>
<template>
  <div
    v-if="props.error?.statusCode !== 404"
    class="h-screen flex justify-center items-center"
  >
    An error occured. Please retry!
  </div>
</template>
