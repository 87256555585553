import type { TabItem } from '../components/Tab/Tab.vue'

interface UseLayoutSettings {
  siteName: string
  articlePageUrl: string
  homePageUrl: string
  tabCategories: TabItem[]
  ayEntityId: string
  videoPageUrl: string
  headerLogo?: string
  headerLogoDarkMode?: string
  tagline?: string
}

export const useLayoutSettings = defineStore('app:layoutSettings', () => {
  const layoutSettings = ref<UseLayoutSettings>()

  function setup(settings: UseLayoutSettings) {
    if (layoutSettings.value === undefined) {
      return (layoutSettings.value = settings)
    }

    throw new Error(
      'Attempted to set up layout properties after they were already set up. Please make sure to set up the layout props only once'
    )
  }

  const settings = computed(() => {
    if (layoutSettings.value === undefined) {
      throw new Error(
        'Attempted to access layout properties before they were set up. Please make sure to set up the layout props before using a layout'
      )
    }

    return layoutSettings.value
  })

  return {
    setup,
    settings,
  }
})
