/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import { useDocumentVisibility } from '@vueuse/core'
import {
  useFeatureFlags,
  type FeatureFlags,
} from '../feature-flags/useFeatureFlags'
import { getTypeTag, getNtpAppVersion } from '../analytics/helper'
import { setupNativeAdAsFallback } from '../../utils/ad-ops/adFunctions'
import { normalizePricePointBucket, priceBuckets } from '../ad-ops/constants'
import { useCpms } from '../ad-ops/cpms/useCpms'

declare global {
  interface Window {
    ayManagerEnv: any
    assertive: any
    isPageVisible: boolean
    assertiveYieldAnalytics: any
    googletag: any
    assertiveQueue: any
  }
}

export interface Slot {
  setTargeting(key: string, value: string): () => void
  getTargeting(key: string): () => string
  getSlotElementId(): () => string
}

export type AyLogImpressionEvent = {
  auctionId?: string
  impression_time: number
  timestamp: number
  version: string
  entityId: string
  clientUUID: string
  sessionUUID: string
  session_start: number
  pageViewUUID: string
  protocol: string
  host: string
  pathname: string
  pathname_split: string[]
  referrer: null | string
  utm: null | string
  acquisition: Record<string, unknown>
  entryPathname: string
  impressionCount: number
  pageViewCount: number
  pageView_impressionCount: number
  pageView_errorCount: number
  client_referrer: null | string
  client_utm: null | string
  client_entryPathname: string
  client_impressionCount: number
  client_pageViewCount: number
  client_sessionCount: number
  window: {
    innerWidth: number
    innerHeight: number
    scrollY: number
  }
  networkInformation: {
    type: string
    effectiveType: string
    downlink: number
    rtt: number
    saveData: boolean
  }
  vitals: Record<string, unknown>
  prebid_timeout: number
  prebid_version: string
  prebid: Record<string, unknown>
  userState: null | string
  layout: null | string
  experiments: any[]
  yieldManager: {
    versionId: number
    versionName: string
    deployId: string
    percentage: number
    isSplitTest: boolean
    isPreview: boolean
    entityId: string
  }
  content: {
    headline: null | string
    author: null | string
    datePublished: null | string
    dateModified: null | string
  }
  revenueBias: number
  timeZone: string
  pageView: {
    isInitialPageView: boolean
  }
  topics: any[]
  impressionUUID: string
  slotPreviousHighestBids: (null | number)[]
  pageView_refreshCount: number
  source: string
  slotId: string
  adUnitPath: string
  meta: {
    externalId: string
    publisherId: number
  }
  unfilled: boolean
  highestPreBid: null | number
  highestPreBid_partner: null | string
  mediaType: string
  creative_width: number
  creative_height: number
  preBidWon: boolean
  timeToRespond: null | number
  ivt: {
    category: number
  }
}

export type AssertiveYieldAnalyticsOptions = {
  /* 1 = all sessions are tracked, 0.5 = 50% of the sessions are tracked... */
  sampleRate: number
  integrations: {
    webAnalytics: boolean
  }
  logUnfilled: boolean
  custom: Record<string, unknown> & {
    userState: unknown
    layout: unknown
    custom_1: unknown
    custom_2: unknown
    custom_3: unknown
  }
  useHistoryChangeTrigger: boolean
}

export type AssertiveYieldOptions = {
  mode: 'production' | 'test'
  debug: boolean
  entityId: string
  analytics: AssertiveYieldAnalyticsOptions
  onAdFulfilled: (ad: AyLogImpressionEvent) => Promise<unknown>
}

type AnalyticsProps = {
  ntp_app_version: string
  host_browser_app_version: string
  type_tag: string
}

interface AssertiveLogImpressionEvent extends Event {
  data?: { payload: AyLogImpressionEvent }
}

// TODO: this will be re-organized and moved after the split
/**
 * This is important so that we don't get duplicates for things like the article
 * page therefore getting site.com/article/ instead of
 * site.com/article/123412312312
 * making it easier for our AdOps tools to agregate things out.
 **/
function getTruncatedUrl() {
  const isV8ArticlePage = window.location.pathname.includes('/v8/article/')
  if (isV8ArticlePage) {
    return `https://${window.location.hostname}/v8/article/`
  }

  const isArticlePage = window.location.pathname.includes('/article/')
  if (isArticlePage) {
    return `https://${window.location.hostname}/article/`
  }

  return `https://${window.location.hostname}${window.location.pathname}`
}

const floorConfig = {
  enabled: true,
  currency: 'USD',
  prebid: true,
  priceBuckets,
  limit: {
    percentage: 1,
  },
}

const injectAYSdk = (
  analyticsProps: AnalyticsProps,
  featureFlags: FeatureFlags,
  isDynamicFloorEnabled: boolean | undefined
) => {
  if (window.assertive && window.ayManagerEnv) {
    return {
      ayManagerEnv: window.ayManagerEnv,
      assertive: window.assertive,
    }
  }

  const { settings } = useLayoutSettings()
  const { ayEntityId } = settings
  const floorOption = isDynamicFloorEnabled ? { floor: floorConfig } : {}

  useHead({
    script: [
      {
        src: `https://${ayEntityId}.ay.delivery/manager/${ayEntityId}`,
        type: 'text/javascript',
        referrerpolicy: 'no-referrer-when-downgrade',
      },
    ],
  })

  const layoutName = featureFlags.name || 'default'
  const assertiveYieldDefaultOptions = {
    ayEntityId,
    debug: true,
  }

  const assertiveYieldAnalyticsDefaultOptions = {
    integrations: {
      webAnalytics: true,
    },
    logUnfilled: true,
    custom: {
      layout: getTruncatedUrl(),
      userState: null,
      custom_1: analyticsProps.type_tag,
      custom_2: analyticsProps.ntp_app_version,
      custom_3: analyticsProps.host_browser_app_version,
      custom_4: layoutName,
    },
    useHistoryChangeTrigger: true,
    ...floorOption,
  }

  window.assertiveYieldAnalytics = window.assertiveYieldAnalytics || {
    ...assertiveYieldAnalyticsDefaultOptions,
  }
  window.assertive = window.assertive || { ...assertiveYieldDefaultOptions }
  window.ayManagerEnv = window.ayManagerEnv || { cmd: [] }

  return {
    ayManagerEnv: window.ayManagerEnv,
    assertive: window.assertive,
  }
}

export function useAssertiveYield(
  options?: Partial<AssertiveYieldOptions>
): void {
  if (!import.meta.client) return

  const previousCalledAction = ref<string | null>(null)
  const { featureFlags } = useFeatureFlags()

  const isDynamicFloorEnabled = featureFlags.adsDynamicFloorPricing?.isEnabled

  window.addEventListener(
    'assertive_logImpression',
    async (e: AssertiveLogImpressionEvent) => {
      try {
        const payload = e?.data?.payload as AyLogImpressionEvent

        const { unfilled, auctionId } = payload
        const isNewRequest = previousCalledAction.value !== auctionId

        if (options?.onAdFulfilled && !unfilled && auctionId && isNewRequest) {
          previousCalledAction.value = auctionId
          await options?.onAdFulfilled(payload)
        }
      } catch (e) {
        console.error(e)
      }
    }
  )

  async function updateSlotTargeting(adUnitInstanceCode: string, slot: any) {
    const { getHourlyCpmForPlacement } = useCpms()

    const placement = adUnitInstanceCode.replace(/__ayManagerEnv__.+$/, '')
    const dynamicFloor = featureFlags.adsDynamicFloorPricing
    const excludeList = dynamicFloor?.excludePlacements ?? []

    // If dynamic floor pricing is disabled or this placement is excluded, update targeting and return.
    if (!isDynamicFloorEnabled || excludeList.includes(placement)) {
      slot.updateTargetingFromMap({ placement })
      return
    }

    // Otherwise, set the targeting with the price floor.
    const cpm = await getHourlyCpmForPlacement(placement)
    slot.updateTargetingFromMap({
      ay_floor: normalizePricePointBucket(cpm),
      placement,
    })
  }

  onMounted(() => {
    window.ayManagerEnv.cmd.push(() => {
      if (isDynamicFloorEnabled) window.assertive.floor = floorConfig
      window.ayManagerEnv.changePage()
    })
  })

  onBeforeMount(async () => {
    if (!import.meta.client) return
    window.ayManagerEnv = window.ayManagerEnv || { cmd: [] }
    const analyticsProps: AnalyticsProps = {
      ntp_app_version: getNtpAppVersion(),
      host_browser_app_version: await getHostBrowserAppVersion(),
      type_tag: await getTypeTag(),
    }
    injectAYSdk(analyticsProps, featureFlags, isDynamicFloorEnabled)

    window.isPageVisible = document.visibilityState === 'visible'
    if (window.isPageVisible) {
      window.ayManagerEnv.cmd.push(() => {
        window.ayManagerEnv.dispatchManualEvent()
      })
    }

    // update targeting for each slot -KVs in GAM360 MUCH match
    window.ayManagerEnv.cmd.push(() => {
      window.ayManagerEnv.onEvent('afterDefineSlot', updateSlotTargeting)
    })

    // Expose analytics function to window object
    const { settings } = useLayoutSettings()
    if (settings.siteName === 'OneNews') {
      if (!window.analytics) {
        window.analytics = HostBrowserApis.analytics
      }
    } else if (settings.siteName === 'ShiftNews') {
      if (!window.analytics) {
        window.analytics = useAnalytics()
      }
    } else {
      console.error(
        'Site name not recognized. Not able to add analytics function for AY.'
      )
    }
  })

  onUnmounted(() => {
    window.ayManagerEnv.offEvent('afterDefineSlot', updateSlotTargeting)
  })

  const pageVisibility = useDocumentVisibility()
  watch(pageVisibility, (value) => {
    const isPageVisible = value === 'visible'
    window.isPageVisible = isPageVisible

    if (isPageVisible) {
      window.ayManagerEnv.cmd.push(() => {
        window.ayManagerEnv.changePage()
        window.ayManagerEnv.dispatchManualEvent()
      })
    }
  })

  // Use Native ads as fallback for empty programmatic ad slots
  setupNativeAdAsFallback()

  return window.ayManagerEnv
}

/**
 * Assertive Yield only looks for divs when it first loads,
 * for SPAs or elements that interactively change, we need to reevaluate the page
 * using the `changePage` and `dispatchManualEvent` functions suggested from
 * their docs.
 **/
export function useAssertiveYieldReevaluate() {
  if (!window.ayManagerEnv) {
    useAssertiveYield()
  }

  window?.ayManagerEnv?.cmd?.push(() => {
    window.ayManagerEnv.changePage()
  })
}

export function getAssertiveYieldCustomLayout(): string {
  if (import.meta.client) {
    return window.assertiveYieldAnalytics?.custom?.layout || ''
  }
  return ''
}

export const logAdImpressionToAY = (
  data: Partial<AyLogImpressionEvent> | null
) => {
  if (!import.meta.client || !data) return

  window.assertiveQueue = window.assertiveQueue || []
  window.assertiveQueue.push(function () {
    if (
      window.assertive &&
      typeof window.assertive.logCustomImpression === 'function'
    ) {
      window.assertive.logCustomImpression(data)
    }
  })
}

export const logAdClickToAY = (slotId: string) => {
  if (!import.meta.client || !slotId) return

  window.assertiveQueue = window.assertiveQueue || []
  window.assertiveQueue.push(function () {
    if (
      window.assertive &&
      typeof window.assertive.logCustomClick === 'function'
    ) {
      window.assertive.logCustomClick(slotId)
    }
  })
}

export default useAssertiveYield
