import { z } from 'zod'

type BlockProps = z.infer<typeof blockPropsSchema>

export type BlockBase = z.infer<typeof blockBaseSchema>

export type SliderBlock = BlockBase & {
  type: 'slider'
  props?: BlockProps
}

export type CarouselBlock = BlockBase & {
  type: 'carousel'
  props?: BlockProps
}

export type TitleBlock = BlockBase & {
  type: 'title' | 'title-l' | 'title-msn'
  props?: BlockProps
}

export type AdTileType = BlockBase['type']

export type VideoBlock = BlockBase & {
  type: 'video' | 'video-s' | 'video-l'
  props?: BlockProps
}

export type ArticleGroupBlock = BlockBase & {
  type: 'article-group'
  props?: BlockProps
}
export type YoloBlock = BlockBase & {
  type: 'yolo-l' | 'yolo-xl' | 'yolo-xxl' | 'yolo-full'
  props?: BlockProps
}

export type AdBlock = z.infer<typeof adBlockSchema>

export type Block = z.infer<typeof blockSchema>

export type GenericElement = {
  type: BlockBase['type']
  ad?: boolean
  onlyWhenGridIsGreaterThan?: number
  x?: number
  y?: number
  classes?: string
  index?: number
  props: BlockProps
  size?: number
}

export type LayoutSection = z.infer<typeof layoutSectionSchema>
export type LayoutSkeleton = z.infer<typeof layoutSkeletonSchema>

export const blockPropsSchema = z
  .object({
    design: z.enum(['google', 'msn']).default('google').optional(),
    pages: z.number().min(2).default(6).optional(), // This will only be used for the article slider and for article group on msn design. If we pass this prop for other components, it will be ignored.
  })
  .passthrough()

const blockBaseSchema = z.object({
  type: z.optional(
    z.union([
      z.literal('slider'),
      z.literal('carousel'),
      z.literal('title'),
      z.literal('title-l'),
      z.literal('video'),
      z.literal('video-s'),
      z.literal('video-l'),
      z.literal('title-msn'),
      z.literal('article-group'),
      z.literal('yolo-xxl'),
      z.literal('yolo-xl'),
      z.literal('yolo-l'),
      z.literal('yolo-full'),
    ])
  ),
  y: z.number(),
  x: z.optional(z.number()),
  repeat: z.optional(z.boolean()),
  size: z.optional(z.number()),
  classes: z.optional(z.string()),
  props: z.optional(blockPropsSchema),
  onlyWhenGridIsGreaterThan: z.optional(z.number()),
})

const adBlockSchema = z.object({
  y: z.number(),
  x: z.optional(z.number()),
  classes: z.optional(z.string()),
  props: z.optional(blockPropsSchema),
  onlyWhenGridIsGreaterThan: z.optional(z.number()),
})

const sliderBlockSchema = blockBaseSchema.extend({
  type: z.literal('slider'),
  props: z.optional(
    blockPropsSchema.extend({
      autoplayInterval: z.optional(z.number()),
      ads: z.optional(
        z.array(
          adBlockSchema
            .pick({ classes: true, props: true })
            .extend({ position: z.number() })
        )
      ),
      contentType: z.enum(['video', 'title']).optional(),
    })
  ),
})

const blockSchema = z.union([blockBaseSchema, sliderBlockSchema, adBlockSchema])

const layoutSectionSchema = z.object({
  name: z.string(),
  title: z.optional(z.string()),
  titleIcon: z.optional(z.string()),
  background: z.optional(z.string()),
  padded: z.optional(z.literal(false)),
  classes: z.optional(z.string()),
  repeat: z.optional(z.literal(true)),
  titles: z.optional(z.array(blockSchema)),
  ads: z.optional(z.array(blockSchema)),
})

export const layoutSkeletonSchema = z.object({
  name: z.string(),
  section: z.array(layoutSectionSchema),
})

export type ComputedLayout = {
  id: string
  background?: string
  padded?: false
  classes?: string
  repeat?: boolean
  title?: string
  titleIcon?: string
  content: GenericElement[][]
}
