import { default as admGRvFqqdwOJMeta } from "/home/runner/work/onenews/onenews/web-services/onenews/pages/adm.vue?macro=true";
import { default as indexqFXH3arifuMeta } from "/home/runner/work/onenews/onenews/web-services/onenews/pages/advertise/index.vue?macro=true";
import { default as _91id_93Ivgxkvv3fRMeta } from "/home/runner/work/onenews/onenews/web-services/onenews/pages/article/[id].vue?macro=true";
import { default as ncNrjx6Gcvy4Meta } from "/home/runner/work/onenews/onenews/web-services/onenews/pages/combo/a/nc.vue?macro=true";
import { default as indexs5NSmEfqoPMeta } from "/home/runner/work/onenews/onenews/web-services/onenews/pages/contact/index.vue?macro=true";
import { default as gxtcBkZJE9SMeta } from "/home/runner/work/onenews/onenews/web-services/onenews/pages/g.vue?macro=true";
import { default as indexHMGcLGZeWOMeta } from "/home/runner/work/onenews/onenews/web-services/onenews/pages/index.vue?macro=true";
import { default as indexTOIquuHiYZMeta } from "/home/runner/work/onenews/onenews/web-services/onenews/pages/p/index.vue?macro=true";
import { default as indexmu4lUQSqyAMeta } from "/home/runner/work/onenews/onenews/web-services/onenews/pages/p/nc/index.vue?macro=true";
import { default as indexmFAFLURX2BMeta } from "/home/runner/work/onenews/onenews/web-services/onenews/pages/p/nc/tab/a/index.vue?macro=true";
import { default as indexf5QZKnzLCJMeta } from "/home/runner/work/onenews/onenews/web-services/onenews/pages/partnerships/index.vue?macro=true";
import { default as indexN50IASi9idMeta } from "/home/runner/work/onenews/onenews/web-services/onenews/pages/privacy-policy/index.vue?macro=true";
import { default as indexkUwEYGFQV4Meta } from "/home/runner/work/onenews/onenews/web-services/onenews/pages/privacy-policy/relationships/index.vue?macro=true";
import { default as searchXydBLrVY0uMeta } from "/home/runner/work/onenews/onenews/web-services/common/pages/search.vue?macro=true";
import { default as indexscvefE8L8NMeta } from "/home/runner/work/onenews/onenews/web-services/onenews/pages/summary/index.vue?macro=true";
import { default as index7nYVeUJzrkMeta } from "/home/runner/work/onenews/onenews/web-services/onenews/pages/tab/v5/a/nc/index.vue?macro=true";
import { default as ncljY5seQl3jMeta } from "/home/runner/work/onenews/onenews/web-services/onenews/pages/tab/v5/nc.vue?macro=true";
import { default as indexxK7nAkKDMhMeta } from "/home/runner/work/onenews/onenews/web-services/onenews/pages/terms/index.vue?macro=true";
import { default as indexksrHsq2wz6Meta } from "/home/runner/work/onenews/onenews/web-services/onenews/pages/v5/index.vue?macro=true";
import { default as indexphz1MqDIyCMeta } from "/home/runner/work/onenews/onenews/web-services/onenews/pages/v5/nc/index.vue?macro=true";
import { default as ncmixiFpbLOzMeta } from "/home/runner/work/onenews/onenews/web-services/onenews/pages/v6/nc.vue?macro=true";
import { default as _91id_93OqnarYBgbyMeta } from "/home/runner/work/onenews/onenews/web-services/onenews/pages/v8/article/[id].vue?macro=true";
import { default as indexWxCfjwsEh7Meta } from "/home/runner/work/onenews/onenews/web-services/onenews/pages/v8/index.vue?macro=true";
import { default as _91id_93yIo7bFKLpxMeta } from "/home/runner/work/onenews/onenews/web-services/onenews/pages/video/[id].vue?macro=true";
export default [
  {
    name: "adm",
    path: "/adm",
    meta: admGRvFqqdwOJMeta || {},
    component: () => import("/home/runner/work/onenews/onenews/web-services/onenews/pages/adm.vue")
  },
  {
    name: "advertise",
    path: "/advertise",
    meta: indexqFXH3arifuMeta || {},
    component: () => import("/home/runner/work/onenews/onenews/web-services/onenews/pages/advertise/index.vue")
  },
  {
    name: "article-id",
    path: "/article/:id()",
    component: () => import("/home/runner/work/onenews/onenews/web-services/onenews/pages/article/[id].vue")
  },
  {
    name: "combo-a-nc",
    path: "/combo/a/nc",
    meta: ncNrjx6Gcvy4Meta || {},
    component: () => import("/home/runner/work/onenews/onenews/web-services/onenews/pages/combo/a/nc.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: indexs5NSmEfqoPMeta || {},
    component: () => import("/home/runner/work/onenews/onenews/web-services/onenews/pages/contact/index.vue")
  },
  {
    name: "g",
    path: "/g",
    component: () => import("/home/runner/work/onenews/onenews/web-services/onenews/pages/g.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/onenews/onenews/web-services/onenews/pages/index.vue")
  },
  {
    name: "p",
    path: "/p",
    meta: indexTOIquuHiYZMeta || {},
    component: () => import("/home/runner/work/onenews/onenews/web-services/onenews/pages/p/index.vue")
  },
  {
    name: "p-nc",
    path: "/p/nc",
    meta: indexmu4lUQSqyAMeta || {},
    component: () => import("/home/runner/work/onenews/onenews/web-services/onenews/pages/p/nc/index.vue")
  },
  {
    name: "p-nc-tab-a",
    path: "/p/nc/tab/a",
    meta: indexmFAFLURX2BMeta || {},
    component: () => import("/home/runner/work/onenews/onenews/web-services/onenews/pages/p/nc/tab/a/index.vue")
  },
  {
    name: "partnerships",
    path: "/partnerships",
    meta: indexf5QZKnzLCJMeta || {},
    component: () => import("/home/runner/work/onenews/onenews/web-services/onenews/pages/partnerships/index.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    meta: indexN50IASi9idMeta || {},
    component: () => import("/home/runner/work/onenews/onenews/web-services/onenews/pages/privacy-policy/index.vue")
  },
  {
    name: "privacy-policy-relationships",
    path: "/privacy-policy/relationships",
    meta: indexkUwEYGFQV4Meta || {},
    component: () => import("/home/runner/work/onenews/onenews/web-services/onenews/pages/privacy-policy/relationships/index.vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/home/runner/work/onenews/onenews/web-services/common/pages/search.vue")
  },
  {
    name: "summary",
    path: "/summary",
    meta: indexscvefE8L8NMeta || {},
    component: () => import("/home/runner/work/onenews/onenews/web-services/onenews/pages/summary/index.vue")
  },
  {
    name: "tab-v5-a-nc",
    path: "/tab/v5/a/nc",
    meta: index7nYVeUJzrkMeta || {},
    component: () => import("/home/runner/work/onenews/onenews/web-services/onenews/pages/tab/v5/a/nc/index.vue")
  },
  {
    name: "tab-v5-nc",
    path: "/tab/v5/nc",
    component: () => import("/home/runner/work/onenews/onenews/web-services/onenews/pages/tab/v5/nc.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: indexxK7nAkKDMhMeta || {},
    component: () => import("/home/runner/work/onenews/onenews/web-services/onenews/pages/terms/index.vue")
  },
  {
    name: "v5",
    path: "/v5",
    meta: indexksrHsq2wz6Meta || {},
    component: () => import("/home/runner/work/onenews/onenews/web-services/onenews/pages/v5/index.vue")
  },
  {
    name: "v5-nc",
    path: "/v5/nc",
    meta: indexphz1MqDIyCMeta || {},
    component: () => import("/home/runner/work/onenews/onenews/web-services/onenews/pages/v5/nc/index.vue")
  },
  {
    name: "v6-nc",
    path: "/v6/nc",
    meta: ncmixiFpbLOzMeta || {},
    component: () => import("/home/runner/work/onenews/onenews/web-services/onenews/pages/v6/nc.vue")
  },
  {
    name: "v8-article-id",
    path: "/v8/article/:id()",
    component: () => import("/home/runner/work/onenews/onenews/web-services/onenews/pages/v8/article/[id].vue")
  },
  {
    name: "v8",
    path: "/v8",
    component: () => import("/home/runner/work/onenews/onenews/web-services/onenews/pages/v8/index.vue")
  },
  {
    name: "video-id",
    path: "/video/:id()",
    component: () => import("/home/runner/work/onenews/onenews/web-services/onenews/pages/video/[id].vue")
  }
]