export default defineNuxtPlugin(() => {
  const { googleTagManagerId, redesignedNtpPaths } = useAppConfig()
  const { gtag, initialize } = useGtag()

  if (!googleTagManagerId) {
    console.warn('Google Tag Manager: Gtag ID not available in app config')
    return
  }

  initialize(googleTagManagerId)

  const route = useRoute()
  // Maintain window.dataLayer field for legacy pages
  if (import.meta.dev === false && !redesignedNtpPaths.includes(route.path)) {
    gtag('js', new Date())
    gtag('config', 'UA-62117061-22')
  }
})
