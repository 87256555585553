import { useElementVisibility, watchOnce } from '@vueuse/core'
import {
  logAdClickToAY,
  logAdImpressionToAY,
} from '../third-party/useAssertiveYield'
import { useAnalytics } from '../analytics/useAnalytics'
import type { AyLogImpressionEvent } from '../third-party/useAssertiveYield'
import type { NtpAdEvent } from '../analytics/types'

const useAdFunctions = () => {
  const { track } = useAnalytics()

  const adClicked = (eventData: NtpAdEvent, slotId: string) => {
    logAdClickToAY(slotId)
    track('NtpAd_Clicked', eventData)
  }

  const adRendered = (
    eventData: NtpAdEvent,
    ayData: Partial<AyLogImpressionEvent>
  ) => {
    logAdImpressionToAY(ayData)
    track('NtpAd_Rendered', eventData)
  }

  /**
   * Renders content when an element becomes visible in the viewport
   * @param element - The HTML element to monitor for visibility
   * @param visibilityTimeoutInMs - Delay in milliseconds before executing callback after visibility
   * @param visibilityThresholdInPercentage - Percentage of element that must be visible (default: 10%)
   * @param callbackFn - Function to execute when element becomes visible
   */
  const renderWhenCurrentElementIsVisible = (
    element: HTMLElement | null,
    visibilityTimeoutInMs: number,
    visibilityThresholdInPercentage: number = 10,
    callbackFn: () => void
  ) => {
    let visibilityTimer: ReturnType<typeof setTimeout> | number = 0

    try {
      if (!element) {
        throw new Error('No element to render when visible')
      }

      if (!visibilityTimeoutInMs) {
        callbackFn()
        return
      }

      const isVisible = useElementVisibility(element, {
        threshold: visibilityThresholdInPercentage / 100,
      })

      watchOnce(isVisible, () => {
        if (visibilityTimer) {
          clearTimeout(visibilityTimer)
        }

        if (isVisible) {
          visibilityTimer = setTimeout(() => {
            callbackFn()
          }, visibilityTimeoutInMs)
        }
      })
    } catch (err) {
      if (visibilityTimer) {
        clearTimeout(visibilityTimer)
      }
      console.error('Unable to render element when visible', err)
    }
  }

  return {
    adClicked,
    adRendered,
    renderWhenCurrentElementIsVisible,
  }
}

export default useAdFunctions
