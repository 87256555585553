import { z } from 'zod'

export const searchParamsNewsCategory = z
  .enum([
    'Automotive',
    'Business',
    'Entertainment',
    'Food',
    'Health',
    'Lifestyle',
    'Money',
    'News',
    'Politics',
    'Science',
    'Shopping',
    'Sports',
    'Technology',
    'Travel',
  ])
  .catch('News')

export type SearchParamsNewsCategory = z.infer<typeof searchParamsNewsCategory>

export const tabCategories: Array<{
  id: SearchParamsNewsCategory | 'Weather'
  label: string | 'Weather'
  [key: string]: unknown
}> = [
  { id: 'News', label: 'News' },
  { id: 'Sports', label: 'Sports' },
  { id: 'Politics', label: 'Politics' },
  { id: 'Entertainment', label: 'Entertainment' },
  { id: 'Money', label: 'Money' },
  { id: 'Lifestyle', label: 'Lifestyle' },
  { id: 'Technology', label: 'Technology' },
  { id: 'Food', label: 'Food' },
  { id: 'Travel', label: 'Travel' },
  { id: 'Health', label: 'Health' },
  { id: 'Automotive', label: 'Automotive' },
  { id: 'Shopping', label: 'Shopping' },
  {
    id: 'Weather',
    label: 'Weather',
    disableSelection: true,
    onClick: openWeatherSite,
  },
]

function openWeatherSite() {
  if (import.meta.client) {
    const weatherUrl =
      'https://www.accuweather.com/en/ca/woburn/m1h/current-weather/3394003?lang=en-us&partner=web_onelaunch_ntp_adc'

    window.open(weatherUrl, '_blank')!.focus()
  }
}
