export function useScriptLoader(scriptOptions: Record<string, unknown>) {
  const isLoaded = ref(false)

  const loadScript = () =>
    new Promise((resolve, reject) => {
      const { onload, onerror, ...options } = scriptOptions

      useHead({
        script: [
          {
            ...options,
            onload: () => {
              isLoaded.value = true
              if (typeof onload === 'function') {
                onload()
              }
              resolve(true)
            },
            onerror: (error) => {
              if (typeof onerror === 'function') {
                onerror()
              }
              reject(error)
            },
          },
        ],
      })
    })

  return {
    isLoaded,
    loadScript,
  }
}
