import { getHostApis } from './getHost'
import type { BaseHostConfig } from '../../types/'

export function getConfig(): Promise<BaseHostConfig | undefined> {
  const { shift, onelaunch } = getHostApis()
  const shiftAPI = shift?.getServerConfig
  if (shiftAPI) {
    return shiftAPI()
  }

  const onelaunchAPI = onelaunch?.getMergedData
  if (onelaunchAPI) {
    return onelaunchAPI()
  }

  return new Promise((resolve) => resolve(undefined))
}
