import type { FeatureFlags } from '../common/composables/feature-flags/useFeatureFlags'

export const featureFlags: FeatureFlags = {
  timestamp: '1980-09-04T19:59:46.737Z',
  name: 'google_inspirational_default',
  homePage: {
    doodle: 'off', // on or off or random
    design: 'google', // google or msn
    searchboxDesign: 'default', // default or v2 or v2-big-yolo or v3
  },
  summaryPage: {
    design: 'google', // google or msn
    siderail: {
      position: 'below-header', // full-height or below-header
      topClass: 'top-siderail',
      middleClass: '',
      bottomClass: '',
      topArticleGroup: {
        design: 'google', // google or msn
        articlesPerGroup: 3, // number of articles being displayed
        type: 'related-stories', // top-stories or related-stories
      },
      bottomArticleGroup: {
        design: 'google', // google or msn
        articlesPerGroup: 3, // number of articles being displayed
        type: 'related-stories', // top-stories or related-stories
      },
    },
    content: {
      aboveTitleClass: '',
      topClass: '',
      belowImageClass: '',
      middleClass: '',
      bottomContentAboveButtonClass: '',
      bottomContentBelowButtonClass: 'under-button',
    },
    showArticleImage: true,
    bottomClass: '',
  },
  userSettings: {
    position: 'off', // off or footer
    defaultSettings: {
      theme: 'light', // light or dark or auto
      articleSummary: true,
    },
    enabledSettings: {
      articleSummary: false,
    },
  },
  sponsoredTiles: {
    provider: 'affinity', // affinity or admarketplace or off
    tilesCount: 5, // number
  },
  // dynamicSponsoredTiles: {
  //   tilesProvider: [
  //     {
  //       provider: 'admarketplace',
  //       tilesCount: 1,
  //     },
  //     {
  //       provider: 'affinity',
  //       tilesCount: 1,
  //     },
  //     {
  //       provider: 'admarketplace',
  //       tilesCount: 3,
  //     },
  //     {
  //       provider: 'direct',
  //       name: 'Ebay',
  //       click_url: 'https://www.ebay.ca/',
  //       image_url:
  //         'https://cdn.deliveryquotecompare.com/news/wp-content/uploads/2018/07/15102454/2000px-EBay_logo.svg-56b81de43df78c0b1364f676.png.webp',
  //       impression_url: 'https://www.ebay.ca/',
  //     },
  //   ],
  //   design: 'msn',
  //   variant: 'default',
  // },
  enableArticleReordering: false,
  enableLocalNewsSearch: true,
  adsDynamicFloorPricing: {
    isEnabled: false,
    excludePlacements: [],
  },
  layoutSkeleton: {
    name: 'google_inspirational_default',
    section: [
      {
        name: 'First Section with no Repeat',
        background: 'secondary',
        titles: [
          { y: 1, type: 'slider' },
          { y: 1, type: 'title', repeat: true },
          { y: 2, type: 'article-group' },
          { y: 2, type: 'title', repeat: true },
          { y: 3, type: 'title', repeat: true },
        ],
        ads: [
          {
            y: 1,
            x: -1,
            classes: 'default_homepage_r1_topright no-padding',
            props: {},
          },
          {
            y: 1,
            x: -3,
            classes: 'default_homepage_r1_c2 no-padding',
            props: {},
            onlyWhenGridIsGreaterThan: 4,
          },

          {
            y: 2,
            x: 1,
            classes: 'default_homepage_r2_c1 no-padding',
            props: {},
            onlyWhenGridIsGreaterThan: 2,
          },
          {
            y: 3,
            x: -1,
            classes: 'default_homepage_r3_right no-padding',
            props: {},
            onlyWhenGridIsGreaterThan: 2,
          },
        ],
      },
      {
        name: 'Second Section',
        padded: false,
        titles: [{ y: 1, type: 'title', repeat: true }],
        ads: [
          {
            y: 1,
            x: 1,
            classes: 'default_homepage_r4_c1 no-padding',
            props: {},
          },
          {
            y: 1,
            x: -1,
            classes: 'default_homepage_r4_right no-padding',
            props: {},
          },
        ],
      },
      {
        name: 'repeated section',
        repeat: true,
        padded: false,
        titles: [
          { y: 1, type: 'title', repeat: true },
          { y: 2, type: 'title', repeat: true },
          { y: 3, type: 'title', repeat: true },
          { y: 4, type: 'title', repeat: true },
        ],
        ads: [
          {
            y: 2,
            x: -1,
            classes: 'default_homepage_endless_right no-padding',
            props: {},
          },
          {
            y: 4,
            x: 1,
            classes: 'default_homepage_endless_right no-padding',
            props: {},
          },
        ],
      },
    ],
  },
}
