import { useWindowSize } from '@vueuse/core'
import { BREAKPOINTS } from '../../utils/layout/constants'

type ScreenSizeProperties = {
  browser_window_size: string
  browser_window_width: number
  browser_window_height: number
  column_count: number
}

export const useScreenSizeProperties = (): ScreenSizeProperties => {
  const { width, height } = useWindowSize()
  const browser_window_size = `${width.value}x${height.value}`
  const browser_window_width = width.value
  const browser_window_height = height.value

  // Based on AutoLayout.vue computed property
  const gridSize = (() => {
    if (width.value < BREAKPOINTS.SM) return 1
    if (width.value < BREAKPOINTS.LG) return 2
    if (width.value < BREAKPOINTS.XL) return 3
    if (width.value < BREAKPOINTS.XXL) return 4
    return 5
  })()
  const column_count = gridSize

  return {
    browser_window_size,
    browser_window_width,
    browser_window_height,
    column_count,
  }
}
