import { storeToRefs } from 'pinia'
import { HostBrowserApis } from '../../utils/host-browser-apis'
import version from '../../../package.json'
import type { AugmentedProperties, NtpPageType } from './types'
import { useScreenSizeProperties } from './useScreenSizeProperties'

declare global {
  interface Window {
    isUserIdle?: boolean
  }
}

function findTypeTagInSearchUrl(url: string | null) {
  if (url == null) {
    return null
  }

  const pattern =
    '([0-9]{1}_[0-9]{4}_[0-9]{3}_[0-9]{4}_[0-9]{3}(_[0-9]{3})?_[0-9]{6})'
  const regexMatching = url.match(pattern)

  if (regexMatching != null) {
    return regexMatching[0]
  }

  return null
}

export async function getTypeTag(): Promise<string> {
  const DEFAULT_TYPE_TAG = '0_1000_100_1000_100_691231'
  try {
    const installInfo = await HostBrowserApis.getConfig()
    if (installInfo?.type_tag) {
      return installInfo.type_tag
    }

    const searchUrl = installInfo?.search_url || null
    const typeTagFromSearchUrl = findTypeTagInSearchUrl(searchUrl)
    if (typeTagFromSearchUrl) {
      return typeTagFromSearchUrl
    }

    const getTypeTagFromUrl = () => {
      const url = new URL(location.href)
      return url.searchParams.get('type') || '0_1000_100_1000_100_691231'
    }

    const typeTagFromUrl = getTypeTagFromUrl()
    if (typeTagFromUrl) {
      return typeTagFromUrl
    }
    return DEFAULT_TYPE_TAG
  } catch (e) {
    console.error(`Error getting type tag: ${e}`)
    return DEFAULT_TYPE_TAG
  }
}

function getNtpPath(): string {
  const route = useRoute()

  if (route.path.includes('/v8/article/')) {
    return '/v8/article'
  } else if (route.path.includes('/article/')) {
    return '/article'
  } else if (route.path.includes('/video/')) {
    return '/video'
  }

  return route.path
}

function getNtpUrl({ fullUrl = false }: { fullUrl?: boolean } = {}): string {
  if (fullUrl) {
    return window.location.href
  }
  return 'https://' + window.location.hostname + window.location.pathname
}

function getNewsContentToggleState() {
  const newsToggleStore = useNewsToggleStore()
  const { newsToggle } = storeToRefs(newsToggleStore)
  return newsToggle.value ? 'on' : 'off'
}

function getCurrentThemeSetting() {
  const themeStore = useTheme()
  return themeStore.mixpanelPropertyName.value
}

export function isRedesignedNtp() {
  const route = useRoute()
  const redesignedNtpPaths: string[] = useAppConfig().redesignedNtpPaths
  return redesignedNtpPaths.some((path) => route.path.startsWith(path))
}

export function getNtpAppVersion(): string {
  return version?.version || 'unknown'
}

export function getPageType(): NtpPageType {
  const path = window.location.pathname

  if (path.includes('article')) {
    return 'article'
  }
  if (path.includes('summary')) {
    return 'summary'
  }
  if (path.includes('video')) {
    return 'video'
  }
  return 'homepage'
}

/**
 * Properties that will be propagated with every event.
 */
export const getAugmentedProperties = async (
  featureFlags: FeatureFlags | undefined,
  eventName: string
): Promise<AugmentedProperties> => {
  const { articleSummary } = useUserSettings(featureFlags)

  const augmentedProperties: AugmentedProperties = {
    ntp_type_tag: await getTypeTag(),
    ntp_window_active:
      window.isPageVisible || document.visibilityState === 'visible',
    ntp_url: getNtpUrl(),
    ntp_app_version: getNtpAppVersion(),
    ntp_layout: getNtpPath(),
    ntp_page_type: getPageType(),
    ntp_news_content_toggle: getNewsContentToggleState(),
    ntp_is_user_idle: window.isUserIdle ?? false,
    ntp_user_settings: {
      theme: getCurrentThemeSetting(),
      ai_news_summary: { ...articleSummary },
    },
  }

  // NtpPage_Viewed and NtpAd_Rendered events have extra properties related to screen size
  if (eventName === 'NtpPage_Viewed' || eventName === 'NtpAd_Rendered') {
    const {
      browser_window_size,
      browser_window_height,
      browser_window_width,
      column_count,
    } = useScreenSizeProperties()

    augmentedProperties.browser_window_size = browser_window_size
    augmentedProperties.browser_window_height = browser_window_height
    augmentedProperties.browser_window_width = browser_window_width

    // Only add grid size on home page
    const route = useRoute()
    if (['/', '/v8', '/v8/'].includes(route.path)) {
      augmentedProperties.column_count = column_count
    }
  }

  // Legacy pages do not need to report on their feature flag state: they have no behaviours
  // This code can be removed once the redesign is the 'default' and legacy pages are replaced.
  if (featureFlags && isRedesignedNtp()) {
    augmentedProperties.ntp_feature_flags = featureFlags
  }

  return augmentedProperties
}

/**
 * The auto-layout function can re-rendered the page during re-size which can cause multiple `NtpAd_Rendered` events to fire for direct ads.
 * This function will send a mixpanel `NtpAd_Rendered` to be sent only once per page-view per ad position on the screen.
 */
const sentAdRenderedPlacements: string[] = []

export function shouldSendEvent(name: string, data: unknown): boolean {
  const isNtpAdEvent = (name: string, data: unknown): data is NtpAdEvent =>
    Boolean(name === 'NtpAd_Rendered' && data && typeof data === 'object')

  if (isNtpAdEvent(name, data)) {
    if (data.ntp_ad_provider !== 'direct' || !data.ntp_placement_name)
      return true
    if (sentAdRenderedPlacements.includes(data.ntp_placement_name)) {
      // If the event was sent for this placement; do not resend
      return false
    }
    // Record that an event is sent of this placement
    sentAdRenderedPlacements.push(data.ntp_placement_name)
  }
  return true
}

/* This helper determines if the provided event name corresponds to
 * an NTP news event, ensuring that the correct type is used for event handling.
 */
export function isNtpNewsEventContentType(
  data: Record<string, unknown>
): data is NtpNewsEvent {
  return (
    data.ntp_content_type === 'article' || data.ntp_content_type === 'video'
  )
}
