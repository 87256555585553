import { useIdle } from '@vueuse/core'

declare global {
  interface Window {
    isUserIdle?: boolean
  }
}

export function useDetectUserActivity(): void {
  if (!import.meta.client) return
  const { idle } = useIdle(5000, { initialState: false })
  watch(
    idle,
    (isIdle) => {
      window.isUserIdle = isIdle
    },
    { immediate: true }
  )
}
