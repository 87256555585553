import { getHostApis } from './getHost'

export async function openInDefaultBrowser(url: string): Promise<void> {
  const { shift, onelaunch } = getHostApis()

  try {
    const shiftAPI = shift?.ntp?.openInDefaultBrowser
    if (shiftAPI) {
      await shiftAPI(url)
      return
    }

    const onelaunchAPI = onelaunch?.openInDefaultBrowser
    if (onelaunchAPI) {
      await onelaunchAPI(url)
      return
    }
  } catch (error) {
    console.error('Failed to open in default browser', error)
  }

  // fallback - for non-browser users or error on host API
  window.open(url, '_blank')
}
