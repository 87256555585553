import { createSharedComposable } from '@vueuse/core'
import { addMilliseconds, isAfter } from 'date-fns'
import { z } from 'zod'
import { layoutSkeletonSchema } from '../data-fetching/auto-layout/index.types'
import {
  aboveFoldSectionAdsSchema,
  type AboveFoldSectionAds,
  type SectionComponents,
} from '../../components/Sections/types/index.types'

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>
}

interface SponsoredTiles {
  provider: 'affinity' | 'admarketplace' | 'off'
  tilesCount: number | 5
  design?: 'msn' | 'google'
  variant?: 'default' | 'circle' | 'large-primary' | 'large-secondary'
}

type DynamicSponsoredTiles = z.infer<typeof dynamicSponsoredTilesSchema>
export type TilesProvider =
  | z.infer<typeof tilesProviderSchema>
  | z.infer<typeof directTilesProviderSchema>

const sponsoredTilesSchema = z.object({
  provider: z.union([
    z.literal('affinity'),
    z.literal('admarketplace'),
    z.literal('off'),
  ]),
  tilesCount: z.number().default(5),
  design: z.union([z.literal('msn'), z.literal('google')]).optional(),
  variant: z
    .union([
      z.literal('default'),
      z.literal('circle'),
      z.literal('large-primary'),
      z.literal('large-secondary'),
    ])
    .optional(),
})

interface UserSettings {
  position: 'off' | 'footer'
  defaultSettings: {
    theme: 'light' | 'dark' | 'auto'
    articleSummary: boolean
  }
  enabledSettings: {
    articleSummary: boolean
  }
}

const userSettingsSchema = z.object({
  position: z
    .union([z.literal('off'), z.literal('footer'), z.literal('navbar')])
    .default('off')
    .transform((value) => {
      // Replace 'navbar' with 'footer' if found in the local storage
      if (value === 'navbar') return 'footer'
      return value
    }),
  defaultSettings: z.object({
    theme: z.union([z.literal('light'), z.literal('dark'), z.literal('auto')]),
    articleSummary: z.boolean().default(true),
  }),
  enabledSettings: z
    .object({
      articleSummary: z.boolean().default(false),
    })
    .default({ articleSummary: false }),
})

const tilesProviderSchema = z.object({
  provider: z.union([z.literal('affinity'), z.literal('admarketplace')]),
  tilesCount: z.number().default(5),
})

const directTilesProviderSchema = z.object({
  provider: z.literal('direct'),
  name: z.string(),
  image_url: z.string(),
  click_url: z.string(),
  impression_url: z.string(),
})

const dynamicSponsoredTilesSchema = z.object({
  tilesProvider: z.array(
    z.union([tilesProviderSchema, directTilesProviderSchema])
  ),
  design: z.union([z.literal('msn'), z.literal('google')]).optional(),
  variant: z
    .union([
      z.literal('default'),
      z.literal('circle'),
      z.literal('large-primary'),
      z.literal('large-secondary'),
    ])
    .optional(),
})

interface HomePageSkeleton {
  doodle: 'off' | 'on' | 'random'
  design: 'google' | 'msn'
  searchboxDesign: 'default' | 'v2' | 'v2-big-yolo' | 'v3'
}

const homePageSkeletonSchema = z.object({
  doodle: z.union([z.literal('off'), z.literal('on'), z.literal('random')]),
  design: z.optional(z.union([z.literal('google'), z.literal('msn')])),
  searchboxDesign: z.optional(
    z.union([
      z.literal('default'),
      z.literal('v2'),
      z.literal('v2-big-yolo'),
      z.literal('v3'),
    ])
  ),
})

export interface ArticleGroupDetails {
  design: 'google' | 'msn'
  articlesPerGroup: number
  type: 'top-stories' | 'related-stories'
}

interface SummaryPageSkeleton {
  design: 'google' | 'msn'
  siderail: {
    position?: 'below-header' | 'full-height'
    topClass: string
    middleClass: string
    bottomClass: string
    topArticleGroup: ArticleGroupDetails | undefined
    bottomArticleGroup: ArticleGroupDetails | undefined
  }
  content: {
    aboveTitleClass: string
    topClass: string
    belowImageClass: string
    middleClass: string
    bottomContentAboveButtonClass: string
    bottomContentBelowButtonClass: string
  }
  showArticleImage: boolean
  bottomClass: string
}

const articleGroupDetailsSchema = z.object({
  design: z.union([z.literal('google'), z.literal('msn')]).optional(),
  articlesPerGroup: z.number().optional().default(3),
  type: z
    .union([z.literal('top-stories'), z.literal('related-stories')])
    .optional()
    .default('top-stories'),
})

const summaryPageSkeletonSchema = z.object({
  design: z.union([z.literal('google'), z.literal('msn')]).optional(),
  siderail: z
    .object({
      position: z
        .union([z.literal('below-header'), z.literal('full-height')])
        .optional(),
      topClass: z.string().optional(),
      middleClass: z.string().optional(),
      bottomClass: z.string().optional(),
      topArticleGroup: articleGroupDetailsSchema.optional(),
      bottomArticleGroup: articleGroupDetailsSchema.optional(),
    })
    .optional(),
  content: z
    .object({
      aboveTitleClass: z.string().optional(),
      topClass: z.string().optional(),
      belowImageClass: z.string().optional(),
      middleClass: z.string().optional(),
      bottomContentAboveButtonClass: z.string().optional(),
      bottomContentBelowButtonClass: z.string().optional(),
    })
    .optional(),
  showArticleImage: z.boolean().optional().default(true),
  bottomClass: z.string().optional(),
})

export interface FeatureFlags {
  ayData?: unknown
  timestamp?: string
  name?: string
  layoutSkeleton: LayoutSkeleton
  homePage?: DeepPartial<HomePageSkeleton>
  summaryPage?: DeepPartial<SummaryPageSkeleton>
  sponsoredTiles?: SponsoredTiles
  userSettings?: UserSettings
  dynamicSponsoredTiles?: DynamicSponsoredTiles
  videoEnabled?: boolean
  sortNewsBy?: string
  enableArticleReordering?: boolean

  aboveFoldSectionShown?: keyof SectionComponents
  aboveFoldSectionAds?: AboveFoldSectionAds
  aboveFoldSectionLeaderboardAd?:
    | 'on_testsection_leader'
    | 'shiftnews_testsection_leader'
    | ''
  enableLocalNewsSearch?: boolean
  adsDynamicFloorPricing?: DynamicFloorPricing
}

interface DynamicFloorPricing {
  isEnabled: boolean
  excludePlacements?: Array<string>
}

const dynamicFloorPricingSchema = z.object({
  isEnabled: z.boolean().default(true),
  excludePlacements: z.array(z.string()).optional().default([]),
})

const featureFlagsSchema = z.object({
  ayData: z.unknown(), // Provided by AdOps for tracking in MixPanel events
  timestamp: z.optional(z.string()),
  name: z.optional(z.string()),
  layoutSkeleton: layoutSkeletonSchema,
  homePage: z.optional(homePageSkeletonSchema.partial()),
  summaryPage: z.optional(summaryPageSkeletonSchema),
  sponsoredTiles: z.optional(sponsoredTilesSchema),
  userSettings: z.optional(userSettingsSchema),
  dynamicSponsoredTiles: z.optional(dynamicSponsoredTilesSchema),
  videoEnabled: z.optional(z.boolean()),
  sortNewsBy: z
    .optional(z.union([z.literal('publishedDate'), z.literal('clusterSize')]))
    .default('publishedDate'),
  enableArticleReordering: z.optional(z.boolean()),

  aboveFoldSectionShown: z.optional(z.literal('LargeCardWithGridSection')),
  aboveFoldSectionAds: z.optional(aboveFoldSectionAdsSchema),
  aboveFoldSectionLeaderboardAd: z
    .union([
      z.literal('on_testsection_leader'),
      z.literal('shiftnews_testsection_leader'),
      z.literal(''),
    ])
    .default(''),
  enableLocalNewsSearch: z.optional(z.boolean()).default(true),
  adsDynamicFloorPricing: z.optional(dynamicFloorPricingSchema),
})

type UseFeatureFlagData = {
  featureFlags: FeatureFlags
}

const FEATURE_FLAG_LOCAL_STORAGE_KEY = 'app:featureFlags'

function loadFromLocalStorage(): FeatureFlags {
  const localStorageFeatureFlag =
    localStorage.getItem(FEATURE_FLAG_LOCAL_STORAGE_KEY) ?? ''
  try {
    const localFeatureFlag = JSON.parse(localStorageFeatureFlag)
    return parseFeatureFlagsOrDefault(localFeatureFlag)
  } catch {
    return toRaw(useAppConfig().defaultFeatureFlags)
  }
}

function saveToLocalStorage(featureFlags: FeatureFlags) {
  const featureFlagStr = JSON.stringify(
    parseFeatureFlagsOrDefault(featureFlags)
  )
  localStorage.setItem(FEATURE_FLAG_LOCAL_STORAGE_KEY, featureFlagStr)
}

function parseFeatureFlagsOrDefault(values: unknown): FeatureFlags {
  const parsedData = featureFlagsSchema.passthrough().safeParse(values)
  if (!parsedData.success) {
    console.warn('Invalid Feature Flags', parsedData.error)
    return toRaw(useAppConfig().defaultFeatureFlags)
  }
  return parsedData.data
}

/**
 * Waits for the assertive yield data to become available. Polls every `stepIntervalMs` until `maxTimeoutMs`
 */
async function waitForAssertiveYield(
  maxTimeoutMs: number,
  sleepInterval: number
) {
  const sleepFn = (timeoutMs: number) =>
    new Promise((resolver) => setTimeout(resolver, timeoutMs))

  const timeout = addMilliseconds(new Date(), maxTimeoutMs)
  while (!window.aYield) {
    console.debug('Waiting for features')
    if (isAfter(new Date(), timeout)) throw new Error('timeout reached')
    await sleepFn(sleepInterval)
  }
  return window.aYield
}

/**
 * Pulls feature flags from assertive yield.
 * Always return the value from localStorage or a default. Update localStorage
 * from AY for the next page load. This removes 'jankiness' during page load.
 */
export const useFeatureFlags = createSharedComposable(
  (): UseFeatureFlagData => {
    const existingFeatureFlags = loadFromLocalStorage()

    async function updateFeatureFlagsFromAssertiveYield() {
      const maxTimeoutMs = 30_000
      const sleepInterval = 250
      try {
        const assertiveYield = await waitForAssertiveYield(
          maxTimeoutMs,
          sleepInterval
        )

        // Only update local storage when the timestamp is newer than the cache
        const currentFeatureTime = new Date(existingFeatureFlags.timestamp ?? 0)
        const newFeatureTime = new Date(
          assertiveYield.featureFlags?.timestamp ?? 0
        )
        if (newFeatureTime <= currentFeatureTime) {
          console.debug('Discarding: features are not new.')
          return
        }

        console.debug(
          'Persisting new feature flags:',
          assertiveYield.featureFlags
        )

        saveToLocalStorage(
          parseFeatureFlagsOrDefault(assertiveYield.featureFlags)
        )
      } catch {
        console.warn('Feature flags not retrieved before timeout.')
      }
    }

    updateFeatureFlagsFromAssertiveYield()
    return { featureFlags: existingFeatureFlags }
  }
)
