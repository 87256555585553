import type {
  NtpAdEvent,
  NtpNewsEvent,
  NtpSettingChanged,
} from '../../../composables/analytics/types'
import {
  isRedesignedNtp,
  getAugmentedProperties,
} from '../../../composables/analytics/helper'

/**
 * The auto-layout function can re-rendered the page during re-size which can cause multiple `NtpAd_Rendered` events to fire for direct ads.
 * This function will send a mixpanel `NtpAd_Rendered` to be sent only once per page-view per ad position on the screen.
 */
const sentAdRenderedPlacements: string[] = []
function shouldSendMixpanelEvent(name: string, data: unknown): boolean {
  const isNtpAdEvent = (name: string, data: unknown): data is NtpAdEvent =>
    Boolean(name === 'NtpAd_Rendered' && data && typeof data === 'object')

  if (isNtpAdEvent(name, data)) {
    if (data.ntp_ad_provider !== 'direct' || !data.ntp_placement_name)
      return true
    if (sentAdRenderedPlacements.includes(data.ntp_placement_name)) {
      // If the event was sent for this placement; do not resend
      return false
    }
    // Record that an event is sent of this placement
    sentAdRenderedPlacements.push(data.ntp_placement_name)
  }
  return true
}

export async function sendMixpanelEvent(
  name: 'query' | 'QueryNonMonetized_Submitted',
  data: {
    location: 'ntp'
    trigger: 'keyboard-return' | 'suggestion-press'
    monetized?: boolean
    active_search_provider?: string
  }
): Promise<void>
export async function sendMixpanelEvent(
  name: `NtpAd_${'Rendered' | 'Clicked'}`,
  data: NtpAdEvent
): Promise<void>
export async function sendMixpanelEvent(
  name:
    | 'NtpOriginalNewsButton_Clicked'
    | `NtpNewsTile_${'Clicked' | 'Rendered'}`,
  data: NtpNewsEvent
): Promise<void>
export async function sendMixpanelEvent(
  name: `NtpShortcutAd_${'Clicked' | 'Rendered'}`,
  data: NtpShortcutEvent
): Promise<void>
export async function sendMixpanelEvent(
  name: 'NtpSetting_Changed',
  data: NtpSettingChanged
): Promise<void>
export async function sendMixpanelEvent(
  name: 'NtpPage_Viewed' | 'NtpMoreNews_Loaded' | 'NtpWeather_Clicked',
  data?: never
): Promise<void>
export function sendMixpanelEvent(
  name: string,
  data: unknown
): Promise<void> | undefined {
  if (!isRedesignedNtp()) {
    mixpanelEventWithData(name, data)
    return
  }
  const { featureFlags } = useFeatureFlags()

  if (!shouldSendMixpanelEvent(name, data)) return

  mixpanelEventWithData(
    name,
    data,
    featureFlags ? toRaw(featureFlags) : undefined
  )
}

async function mixpanelEventWithData(
  name: string,
  data: unknown,
  featureFlags?: FeatureFlags
) {
  const augmentedProperties = await getAugmentedProperties(featureFlags, name)
  const eventData = {
    ...augmentedProperties,
    ...(data || {}),
  }

  if (import.meta.client) {
    window.mixpanelEventCallLog = window.mixpanelEventCallLog || []
    window.mixpanelEventCallLog.push({ name, eventData })
  }

  const { shift, chrome, onelaunch } = getHostApis()
  const shiftAPI = shift?.analytics?.track
  if (shiftAPI) {
    return shiftAPI(name, eventData)
  }

  const oneLaunchAPI = onelaunch?.sendEvent
  if (oneLaunchAPI) {
    oneLaunchAPI(name, eventData)
    return
  }

  const chromeAPI = chrome?.runtime?.sendEvent
  if (chromeAPI) {
    chromeAPI(name, eventData)
  }
}
