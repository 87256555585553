import { outbrainPlacementWidgets } from './ad_ops/outbrainPlacementWidgets'
import { featureFlags } from './default_feature_flags'

export default defineAppConfig({
  nuxtIcon: {},
  defaultFeatureFlags: featureFlags,
  siteName: 'OneNews',
  defaultCpm: 2.0588873109900008,
  urls: {
    home: '/',
    article: '/article',
    video: '/video',
  },
  directAdsHostedConfigUrl:
    'http://localhost:2772/applications/WebTier/environments/OneNews/configurations/OneNews-DirectAds',
  redesignedNtpPaths: ['/v8', '/v8/article', '/', '/article'],
  googleTagManagerId: 'GTM-WNFNMPW',
  assertiveYield: {
    apiUrl: 'https://suite.assertiveyield.com/api/v2',
    entityId: 'sRm8pLMYisn9D7DyN',
  },
  outbrain: {
    installationKey: 'ONELA2JBJF199O5BKEIEEDG98',
    widgets: outbrainPlacementWidgets,
  },
})
