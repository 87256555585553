import { outbrainPlacementWidgets } from './ad_ops/outbrainPlacementWidgets'
import { featureFlags } from './default_feature_flags'

export default defineAppConfig({
  nuxtIcon: {},
  defaultFeatureFlags: featureFlags,
  directAdsHostedConfigUrl: '',
  redesignedNtpPaths: [''],
  googleTagManagerId: '',
  urls: {
    home: '/',
    article: '/article',
    video: '/video',
  },
  assertiveYield: {
    apiUrl: 'https://suite.assertiveyield.com/api/v2',
    entityId: '',
  },
  outbrain: {
    installationKey: '',
    widgets: outbrainPlacementWidgets,
  },
})
