import { createSharedComposable, useLocalStorage } from '@vueuse/core'
import { isRedesignedNtp } from './analytics/helper'

export type ColorThemeOption = 'system' | 'light' | 'dark'

export function isColorTheme(value: string): value is ColorThemeOption {
  return ['system', 'light', 'dark'].includes(value)
}

export const useTheme = createSharedComposable(() => {
  const { featureFlags } = useFeatureFlags()
  const colorMode = useColorMode()
  const isUserSelected = useLocalStorage('app:userSelectedTheme', false)
  if (!isUserSelected.value) {
    colorMode.preference =
      featureFlags.userSettings?.defaultSettings.theme ?? 'system'
  }

  const mixpanelPropertyName = computed(() => {
    if (colorMode.preference === 'system' && colorMode.value === 'light')
      return 'auto-light'
    else if (colorMode.preference === 'system' && colorMode.value === 'dark')
      return 'auto-dark'
    return colorMode.preference
  })

  const updateTheme = (newTheme: ColorThemeOption) => {
    const { track } = useAnalytics()

    isUserSelected.value = true
    colorMode.preference = newTheme
    document.documentElement.className = `${colorMode.value} bg-background-default dark:bg-background-neutral-subtle`
    track('NtpSetting_Changed', {
      setting_name: 'ntp_theme',
      setting_value: mixpanelPropertyName.value,
    })
    setProfileProperty('ntp_theme', mixpanelPropertyName.value)
  }

  if (isRedesignedNtp()) {
    colorMode.preference =
      (isUserSelected
        ? colorMode.preference
        : featureFlags.userSettings?.defaultSettings.theme) ?? 'system'
  }

  return { theme: colorMode, updateTheme, mixpanelPropertyName }
})
