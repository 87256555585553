import { usePreferredDark } from '@vueuse/core'

export default function useTabFavicon(getIcon: () => string) {
  function updateFavicon(favicon: () => string, rel: string) {
    watch(
      favicon,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          const element = document?.head.querySelector<HTMLLinkElement>(
            `link[rel="${rel}"]`
          )

          if (element) element.href = newValue
        }
      },
      { immediate: true }
    )
  }

  const isDark = usePreferredDark()

  const favicon = computed(() => {
    const icon = getIcon()

    return {
      ico: `${useRuntimeConfig().app.cdnURL}/${icon}.ico`,
      svg: `${useRuntimeConfig().app.cdnURL}/${icon}.svg`,
      png96x96: `${useRuntimeConfig().app.cdnURL}/${icon}-96x96.png`,
      png180x180: `${useRuntimeConfig().app.cdnURL}/${icon}-180x180.png`,
    }
  })

  // Creates the favicon links with the proper attributes.
  useHead({
    link: [
      {
        rel: 'shortcut icon',
        type: 'image/x-icon',
        href: favicon.value.ico,
      },
      {
        rel: 'alternate icon',
        type: 'image/svg+xml',
        href: favicon.value.svg,
      },
      {
        rel: 'icon',
        sizes: '96x96',
        href: favicon.value.png96x96,
      },
      {
        rel: 'apple-touch-icon',
        sizes: '180x180',
        href: favicon.value.png180x180,
      },
    ],
  })

  // Updates the favicons whenever the dark mode preference changes.
  updateFavicon(() => favicon.value.ico, 'shortcut icon')
  updateFavicon(() => favicon.value.svg, 'alternate icon')
  updateFavicon(() => favicon.value.png96x96, 'icon')
  updateFavicon(() => favicon.value.png180x180, 'apple-touch-icon')

  return { isDark, favicon }
}
