import { getHostApis } from '../getHost'

export function incrementProfileProperty(
  name: string,
  value: string | number
): void {
  const { shift, chrome, onelaunch } = getHostApis()

  const shiftAPI = shift?.analytics?.updateNumericProfileProperties
  if (shiftAPI) {
    return shiftAPI({ [name]: value })
  }

  const oneLaunchAPI = onelaunch?.addProfileProperty
  if (oneLaunchAPI) {
    oneLaunchAPI(name, value.toString())
    return
  }

  const chromeAPI = chrome?.runtime?.addProfileProperty
  if (chromeAPI) {
    chromeAPI(name, value.toString())
  }
}
