import { z } from 'zod'
import type LargeCardWithGridSection from '../LargeCardWithGridSection.vue'

const adBaseSchema = z.object({
  y: z.number(),
  x: z.number(),
  classes: z.string(),
})

export const aboveFoldSectionAdsSchema = z.object({
  grid3: z.array(adBaseSchema),
  grid2: z.array(adBaseSchema),
  grid1: z.array(adBaseSchema),
})

export type SectionComponents = {
  LargeCardWithGridSection: typeof LargeCardWithGridSection
}

export type AdBase = z.infer<typeof adBaseSchema>
export type AboveFoldSectionAds = z.infer<typeof aboveFoldSectionAdsSchema>
