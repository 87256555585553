import { getSearchUrl } from './getSearchUrl'
import { getConfig } from './getConfig'
import { getHostApis } from './getHost'
import { openInDefaultBrowser } from './openInDefaultBrowser'
import { isCurrentlyAtHomeTab } from './isCurrentlyAtHomeTab'
import { getInstallInfo } from './getInstallInfo'
import { getSearchEngineConfig } from './getSearchEngineConfig'
import { getHostBrowserAppVersion } from './getHostBrowserAppVersion'
import {
  incrementProfileProperty,
  sendMixpanelEvent,
  setProfileProperty,
} from './analytics'

export const HostBrowserApis = {
  getSearchUrl,
  getConfig,
  getHostApis,
  openInDefaultBrowser,
  getHostBrowserAppVersion,
  setProfileProperty,
  isCurrentlyAtHomeTab,
  getInstallInfo,
  getSearchEngineConfig,
  analytics: {
    incrementProfileProperty,
    sendMixpanelEvent,
    setProfileProperty,
  },
}
