<script setup lang="ts">
  import { usePreferredDark } from '@vueuse/core'
  import { isCurrentlyAtHomeTab } from '../common/utils/host-browser-apis/isCurrentlyAtHomeTab'
  import { getHostApis } from '../common/utils/host-browser-apis/getHost'
  import { useDetectUserActivity } from './composables/useDetectUserActivity'
  import useDatadogBrowserLogsClient from '../common/composables/third-party/useDatadogBrowserLogs.client'
  import oneNewsLogo from './assets/icons/one_news_logo.svg?url'
  import oneNewsLogoDarkMode from './assets/icons/one_news_logo_dark.svg?url'
  import { useLocale } from '../common/store/LocaleStore'
  import useTabFavicon from '../common/composables/useTabFavicon'
  import { useI18n } from 'vue-i18n'

  function isExcludedRouteFromColorMode() {
    const route = useRoute()
    const excludedRoutes = [
      '/contact',
      '/advertise',
      '/partnerships',
      '/privacy-policy',
      '/terms',
    ]
    return excludedRoutes.includes(route.path)
  }

  useHead({
    htmlAttrs: {
      lang: 'en',
      class: 'bg-background-default dark:bg-background-neutral-subtle',
    },
    link: [
      { rel: 'preconnect', href: 'https://fonts.gstatic.com', crossorigin: '' },
      { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
      {
        rel: 'stylesheet',
        href: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Roboto&display=swap',
      },
    ],
    meta: [
      {
        name: 'apple-mobile-web-app-title',
        content: 'OneNews',
      },
    ],
  })

  const isOnOneLaunch = () => {
    const { onelaunch } = getHostApis()
    return Boolean(onelaunch)
  }

  const isHomeTab = isCurrentlyAtHomeTab()
  const isDark = usePreferredDark()

  function getFavicon() {
    const suffix = isDark.value ? '-dark' : '-light'

    return isOnOneLaunch()
      ? isHomeTab
        ? `home-favicon${suffix}`
        : `search-favicon${suffix}`
      : 'onenews-favicon'
  }

  useTabFavicon(getFavicon)

  const { setLocale } = useI18n()

  onMounted(() => {
    useDetectUserActivity()

    // Set color mode to light for excluded routes
    // This is set using the color mode directly (instead of updateTheme) to avoid saving the color mode as a user preference
    const colorMode = useColorMode()
    if (isExcludedRouteFromColorMode()) {
      colorMode.value = 'light'
    }
  })

  if (import.meta.client) {
    const { locale } = useLocale()
    setLocale(locale.toLowerCase())

    const route = useRoute()
    if (route.name !== 'summary') {
      useTaboolaAdsDispose()
    }
  }

  const tabCategories: Array<{
    id: SearchParamsNewsCategory | 'Weather'
    label: string | 'Weather'
    [key: string]: unknown
  }> = [
    { id: 'News', label: 'News' },
    { id: 'Sports', label: 'Sports' },
    { id: 'Politics', label: 'Politics' },
    { id: 'Entertainment', label: 'Entertainment' },
    { id: 'Money', label: 'Money' },
    { id: 'Lifestyle', label: 'Lifestyle' },
    { id: 'Technology', label: 'Technology' },
    { id: 'Food', label: 'Food' },
    { id: 'Travel', label: 'Travel' },
    { id: 'Health', label: 'Health' },
    { id: 'Automotive', label: 'Automotive' },
    { id: 'Shopping', label: 'Shopping' },
    {
      id: 'Weather',
      label: 'Weather',
      disableSelection: true,
      onClick: openWeatherSite,
    },
  ]

  function openWeatherSite() {
    if (import.meta.client) {
      const weatherUrl =
        'https://www.accuweather.com/en/ca/woburn/m1h/current-weather/3394003?lang=en-us&partner=web_onelaunch_ntp_adc'

      window.open(weatherUrl, '_blank')!.focus()
    }
  }

  useLayoutSettings().setup({
    siteName: 'OneNews',
    homePageUrl: '/',
    articlePageUrl: '/article',
    videoPageUrl: '/video',
    tabCategories,
    ayEntityId: 'sRm8pLMYisn9D7DyN',
    headerLogo: isOnOneLaunch() ? undefined : oneNewsLogo,
    headerLogoDarkMode: isOnOneLaunch() ? undefined : oneNewsLogoDarkMode,
  })

  useAssertiveYield()
  useDatadogBrowserLogsClient({
    service: 'onenews',
  })
</script>

<template>
  <NuxtPage />
  <UNotifications />
</template>

<!-- eslint-disable-next-line vue/enforce-style-attribute -->
<style>
  body {
    font-family:
      Inter,
      Roboto,
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Helvetica,
      Arial,
      sans-serif,
      serif,
      monospace;

    @apply bg-background-default dark:bg-background-neutral-subtle;
  }
</style>
