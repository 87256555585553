import mixpanel from 'mixpanel-browser'
import { HostBrowserApis } from '../../utils/host-browser-apis'

let isMixpanelSDKInitiated = false

/**
 * Performs some rudimentary filtering to ensure we do not re-fire the same event multiple times.
 * For example:
 *  - The auto-layout function can re-rendered the page during re-size which can cause multiple `NtpAd_Rendered` events to fire for direct ads.
 *  - PageViewed can be unintentionally sent twice if Layouts are nested. This will catch that problem as well.
 */
const sentAdRenderedPlacements: string[] = []
let sentPageViewedEvent: boolean = false
function shouldSendMixpanelEvent(name: string, eventData: unknown): boolean {
  const isNtpAdEvent = (name: string, data: unknown): data is NtpAdEvent =>
    Boolean(name === 'NtpAd_Rendered' && data && typeof data === 'object')

  if (isNtpAdEvent(name, eventData)) {
    if (eventData.ntp_ad_provider !== 'direct' || !eventData.ntp_placement_name)
      return true
    if (sentAdRenderedPlacements.includes(eventData.ntp_placement_name)) {
      // If the event was sent for this placement; do not resend
      return false
    }
    // Record that an event is sent of this placement
    sentAdRenderedPlacements.push(eventData.ntp_placement_name)
  }
  if (name === 'NtpPage_Viewed') {
    if (sentPageViewedEvent) return false
    sentPageViewedEvent = true
  }
  return true
}

function sendEventWithMixpanelSDK(
  name: string,
  data?: Record<string, unknown>
) {
  if (!import.meta.client) return

  if (!isMixpanelSDKInitiated) {
    const config = useRuntimeConfig()

    // Avoid calling mixpanel.init without the proper token.
    // TODO: Log this error and add an alarm to monitor it.
    if (!config.public.mixpanelToken) return

    mixpanel.init(config.public.mixpanelToken)

    isMixpanelSDKInitiated = true
  }

  mixpanel.track(name, data)
}

export function sendEvent(
  name: string,
  eventData?: Record<string, unknown>
): void {
  if (!import.meta.client) return

  if (!shouldSendMixpanelEvent(name, eventData)) return

  window.mixpanelEventCallLog = window.mixpanelEventCallLog || []
  window.mixpanelEventCallLog.push({ name, eventData })

  const { shift, chrome, onelaunch } = HostBrowserApis.getHostApis()
  const shiftAPI = shift?.analytics?.track
  if (shiftAPI) {
    shiftAPI(name, eventData)
    return
  }

  const oneLaunchAPI = onelaunch?.sendEvent
  if (oneLaunchAPI) {
    oneLaunchAPI(name, eventData)
    return
  }

  const chromeAPI = chrome?.runtime?.sendEvent
  if (chromeAPI) {
    chromeAPI(name, eventData)
    return
  }

  sendEventWithMixpanelSDK(name, eventData)
}
