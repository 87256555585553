import type { MixpanelEventMap } from './types'
import {
  isRedesignedNtp,
  getAugmentedProperties,
  shouldSendEvent,
  isNtpNewsEventContentType,
} from './helper'
import { sendEvent } from './sendEvent'

async function getEventData(
  eventName: string,
  data: Record<string, unknown> = {}
) {
  const { featureFlags } = useFeatureFlags()
  const augmentedProperties = await getAugmentedProperties(
    isRedesignedNtp() ? featureFlags : undefined,
    eventName
  )

  return { ...augmentedProperties, ...data }
}

function debounceRenderedTilesEvent(delay = 300) {
  const newsTileRenderedCount = ref({ article: 0, video: 0 })
  let timeout: NodeJS.Timeout | null = null

  function debounce(contentType: 'article' | 'video') {
    if (timeout) clearTimeout(timeout)

    newsTileRenderedCount.value[contentType] += 1

    timeout = setTimeout(async () => {
      const eventData = await getEventData('NtpNewsTile_Rendered', {
        article_tiles_rendered_count: newsTileRenderedCount.value.article,
        video_tiles_rendered_count: newsTileRenderedCount.value.video,
      })

      sendEvent('NtpNewsTile_Rendered', eventData)

      newsTileRenderedCount.value = { article: 0, video: 0 }
      timeout = null
    }, delay)
  }

  return debounce
}

const sendRenderedNewsTileEvent = debounceRenderedTilesEvent()

export const useAnalytics = () => {
  async function track<K extends keyof MixpanelEventMap>(
    name: string,
    data?: MixpanelEventMap[K]
  ): Promise<void> {
    if (!shouldSendEvent(name, data)) return

    if (
      name === 'NtpNewsTile_Rendered' &&
      data &&
      isNtpNewsEventContentType(data)
    ) {
      sendRenderedNewsTileEvent(data.ntp_content_type)
    } else {
      const eventData = await getEventData(name, data)
      sendEvent(name, eventData)
    }
  }

  return { track }
}
