type PriceBucket = {
  min: number
  max: number
  increment: number
}

export const priceBuckets: PriceBucket[] = [
  { min: 0, max: 2, increment: 0.05 },
  { min: 2, max: 5, increment: 0.1 },
  { min: 5, max: 10, increment: 0.25 },
  { min: 10, max: 20, increment: 0.5 },
]

export const normalizePricePointBucket = (cpm: number): string => {
  const bucket =
    priceBuckets.find((bucket) => cpm >= bucket.min && cpm < bucket.max) || null
  if (!bucket) return '0.00'

  const pricePoint =
    Math.floor((cpm - bucket.min) / bucket.increment) * bucket.increment +
    bucket.min

  return pricePoint.toFixed(2)
}
