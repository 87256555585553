import { createSharedComposable } from '@vueuse/core'
import type { RevenueResponse } from '../../../server/services/bi-report-service/index.types'

const CPMS_LOCAL_STORAGE_KEY = 'app:ad-cpms'

export interface LocalStorageCpmData {
  date: string
  prices: Record<string, RevenueResponse>
}

const getCurrentDateString = (): string =>
  new Date().toISOString().split('T')[0]

const getUtcHourOfDay = (): string =>
  new Date().getUTCHours().toString().padStart(2, '0')

const getDefaultCpm = (): number => {
  return Number(useAppConfig().defaultCpm)
}

const loadFromLocalStorage = (): LocalStorageCpmData | null => {
  try {
    const raw = localStorage.getItem(CPMS_LOCAL_STORAGE_KEY)
    if (!raw) return null
    const parsed: LocalStorageCpmData = JSON.parse(raw)
    return parsed.date === getCurrentDateString() ? parsed : null
  } catch (error) {
    console.error('Error reading stored data from localStorage:', error)
    localStorage.removeItem(CPMS_LOCAL_STORAGE_KEY)
    return null
  }
}

const saveToLocalStorage = (newData: LocalStorageCpmData): void => {
  try {
    localStorage.setItem(CPMS_LOCAL_STORAGE_KEY, JSON.stringify(newData))
  } catch (error) {
    console.error('Error saving data to localStorage:', error)
  }
}

const fetchPlacementData = async (): Promise<
  Record<string, RevenueResponse>
> => {
  const dateStr = getCurrentDateString()
  const url = `/api/v2/ad-placement/cpm?date=${dateStr}`

  return await $fetch<Record<string, RevenueResponse>>(url)
}

function useCpmsRaw() {
  const cachedData = ref<LocalStorageCpmData | null>(null)

  async function getHourlyCpmForPlacement(placement: string): Promise<number> {
    const hourOfDay = getUtcHourOfDay()

    const currentCache = cachedData.value || loadFromLocalStorage()
    if (currentCache) {
      cachedData.value = currentCache
      if (currentCache.prices?.[hourOfDay]?.cpms?.[placement] != null) {
        return Number(currentCache.prices[hourOfDay].cpms[placement])
      }
    }

    // fallback
    const newData = await fetchPlacementData()
    const updatedCache: LocalStorageCpmData = {
      date: getCurrentDateString(),
      prices: newData,
    }
    saveToLocalStorage(updatedCache)
    cachedData.value = updatedCache

    if (newData[hourOfDay]?.cpms?.[placement] != null) {
      return Number(newData[hourOfDay].cpms[placement])
    } else {
      console.warn(
        'Placement not found in fetched data; returning default CPM.'
      )
      return getDefaultCpm()
    }
  }

  return { getHourlyCpmForPlacement }
}
export const useCpms = createSharedComposable(useCpmsRaw)
