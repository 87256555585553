/**
 * Pinia store for managing locale settings in the application.
 * This store determines the current locale based on URL parameters, browser settings,
 * and our supported locales. It also provides utilities for handling and validating locales.
 *
 * @module LocaleStore
 */

import { defineStore } from 'pinia'
import { z } from 'zod'
import { useSearchParams } from '../composables/data-fetching/useSearchParams'

export const supportedLocaleSchema = z.enum(['EN', 'FR', 'DE'])
export type SupportedLocales = z.infer<typeof supportedLocaleSchema>

/**
 * @returns {Object} - The reactive `locale` property.
 *
 * @example
 * const localeStore = useLocale()
 * localeStore.locale // 'EN' | 'FR' | 'DE' (static)
 * const { locale } = storeToRefs(localeStore) // from pinia
 * locale.value // 'EN' | 'FR' | 'DE' (reactive)
 */
export const useLocale = defineStore('locale', () => {
  const browserLocale = ref<SupportedLocales | undefined>(undefined)
  const { selectedLang: localeParam } = useSearchParams()

  /**
   * Computed property that determines the current locale.
   * The locale is resolved in the following order:
   * 1. The first supported language from the `lang` URL parameter.
   * 2. The browser's locale.
   * 3. Defaults to 'EN' if no valid locale is found.
   */
  const locale = computed<SupportedLocales>(() => {
    if (Array.isArray(localeParam.value)) {
      return (
        firstSupportedLang(localeParam.value) ?? browserLocale.value ?? 'EN'
      )
    }

    return supportedLang(localeParam.value ?? '') ?? browserLocale.value ?? 'EN'
  })

  // Utility functions

  const languageCode = (language?: string): string =>
    language ? language.slice(0, 2).toUpperCase() : ''

  const supportedLang = (lang: string): SupportedLocales | undefined => {
    const code = languageCode(lang)
    const parsedLang = supportedLocaleSchema.safeParse(code)
    return parsedLang.success ? parsedLang.data : undefined
  }

  const firstSupportedLang = (langs: string[] | readonly string[]) => {
    for (const lang of langs) {
      if (supportedLang(lang)) return supportedLang(lang)
    }
    return undefined
  }

  /**
   * Watches for changes in the `lang` URL parameter and updates it
   * to the first supported locale if necessary.
   */
  watch(
    () => localeParam.value,
    (newLang) => {
      if (!newLang) {
        localeParam.value = undefined
        return
      }
      const supportedParamLocale = Array.isArray(newLang)
        ? firstSupportedLang(newLang)
        : supportedLang(newLang)
      localeParam.value = supportedParamLocale
    },
    { immediate: true }
  )

  /**
   * Tracks the browser's language settings and updates the `browserLocale` accordingly.
   * This is initialized on store creation and updated whenever the browser's language changes.
   */
  const supportedBrowserLocale = firstSupportedLang(navigator.languages)
  browserLocale.value = supportedBrowserLocale

  window.addEventListener('languagechange', () => {
    const supportedBrowserLocale = firstSupportedLang(navigator.languages)
    browserLocale.value = supportedBrowserLocale
  })

  return { locale }
})
