/**
 * Function that manages URL search parameters for category and language selection.
 * It provides reactive references for the selected category and language, and synchronizes them
 * with the URL search parameters.
 * This does not allow for setting the URL search parameters directly.
 *
 * @returns {UseSearchParams} An object containing:
 * - `selectedCategory`: A reactive reference to the selected news category.
 * - `selectedLang`: A reactive reference to the selected language or languages.
 *
 * @remarks
 * - The `category` parameter defaults to `'News'` if it is invalid or not provided.
 * - The `lang` parameter can be a string or an array of strings,
 *   but only the first value is used if it is an array.
 */
import type { Ref } from 'vue'
import { useUrlSearchParams } from '@vueuse/core'
import {
  searchParamsNewsCategory,
  type SearchParamsNewsCategory,
} from '../../utils/newsCategoryTab'

interface UseSearchParams {
  selectedCategory: Ref<SearchParamsNewsCategory>
  selectedLang: Ref<string | string[] | undefined>
}

type UrlParams = {
  category: string
  lang: string | string[] | undefined
}

export function useSearchParams(): UseSearchParams {
  const urlParams = useUrlSearchParams<UrlParams>('history', {
    initialValue: { category: 'News', lang: undefined },
  })

  onMounted(() => {
    const result = searchParamsNewsCategory.safeParse(urlParams.category)
    if (!result.success) {
      urlParams.category = 'News'
    }
  })

  const selectedCategory = ref<SearchParamsNewsCategory>(
    searchParamsNewsCategory.parse(urlParams.category)
  )

  watch(selectedCategory, (newValue) => {
    urlParams.category = newValue
  })

  const selectedLang = ref(urlParams.lang)

  watch(
    selectedLang,
    (newValue) => {
      if (Array.isArray(newValue)) {
        urlParams.lang = newValue[0]
      } else {
        urlParams.lang = newValue
      }
    },
    { immediate: true }
  )

  return { selectedCategory, selectedLang }
}
