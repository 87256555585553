import revive_payload_client_2dH2OdOIw9 from "/home/runner/work/onenews/onenews/web-services/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.4.1_@types+node@22.13.1_encoding@0.1.13_eslint@9.16.0_jiti@2_x2trjc4un476rdknkcgoxwt6m4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_qH8aTiwQAt from "/home/runner/work/onenews/onenews/web-services/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.4.1_@types+node@22.13.1_encoding@0.1.13_eslint@9.16.0_jiti@2_x2trjc4un476rdknkcgoxwt6m4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_2Tlu0iQSPw from "/home/runner/work/onenews/onenews/web-services/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.4.1_@types+node@22.13.1_encoding@0.1.13_eslint@9.16.0_jiti@2_x2trjc4un476rdknkcgoxwt6m4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_3sbkFHXPUC from "/home/runner/work/onenews/onenews/web-services/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.4.1_@types+node@22.13.1_encoding@0.1.13_eslint@9.16.0_jiti@2_x2trjc4un476rdknkcgoxwt6m4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_3HBBeXYGiT from "/home/runner/work/onenews/onenews/web-services/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.4.1_@types+node@22.13.1_encoding@0.1.13_eslint@9.16.0_jiti@2_x2trjc4un476rdknkcgoxwt6m4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import view_transitions_client_iuLgcIDv9H from "/home/runner/work/onenews/onenews/web-services/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.4.1_@types+node@22.13.1_encoding@0.1.13_eslint@9.16.0_jiti@2_x2trjc4un476rdknkcgoxwt6m4/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_J2rnWQK0jK from "/home/runner/work/onenews/onenews/web-services/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.4.1_@types+node@22.13.1_encoding@0.1.13_eslint@9.16.0_jiti@2_x2trjc4un476rdknkcgoxwt6m4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_u2Of28uQtQ from "/home/runner/work/onenews/onenews/web-services/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.31.0_typescript@5.8.2_vue@3.5.13_typescript@5.8.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/onenews/onenews/web-services/onenews/.nuxt/components.plugin.mjs";
import prefetch_client_5JXhyzEIC1 from "/home/runner/work/onenews/onenews/web-services/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.4.1_@types+node@22.13.1_encoding@0.1.13_eslint@9.16.0_jiti@2_x2trjc4un476rdknkcgoxwt6m4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_XvVKEavBaC from "/home/runner/work/onenews/onenews/web-services/node_modules/.pnpm/nuxt-gtag@3.0.2_magicast@0.3.5_rollup@4.31.0/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_NsKj8exyR8 from "/home/runner/work/onenews/onenews/web-services/node_modules/.pnpm/@nuxt+icon@1.4.10_magicast@0.3.5_rollup@4.31.0_vite@5.3.6_@types+node@22.13.1_sass@1.82.0_ter_fy3njbbbefg3z2tgtppuiaoc2u/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import slideovers_f9uqWAH9y0 from "/home/runner/work/onenews/onenews/web-services/node_modules/.pnpm/@nuxt+ui@2.17.0_axios@1.7.9_debug@4.4.0__change-case@4.1.2_magicast@0.3.5_rollup@4.31.0_vite@_bydtsomig4mxl34z57q7qpovnu/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_EfCSfR5a3a from "/home/runner/work/onenews/onenews/web-services/node_modules/.pnpm/@nuxt+ui@2.17.0_axios@1.7.9_debug@4.4.0__change-case@4.1.2_magicast@0.3.5_rollup@4.31.0_vite@_bydtsomig4mxl34z57q7qpovnu/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_6Znh8khcbD from "/home/runner/work/onenews/onenews/web-services/node_modules/.pnpm/@nuxt+ui@2.17.0_axios@1.7.9_debug@4.4.0__change-case@4.1.2_magicast@0.3.5_rollup@4.31.0_vite@_bydtsomig4mxl34z57q7qpovnu/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_DXVibP6oIh from "/home/runner/work/onenews/onenews/web-services/node_modules/.pnpm/@nuxtjs+color-mode@3.4.2_magicast@0.3.5/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_EIiUoMGLeQ from "/home/runner/work/onenews/onenews/web-services/node_modules/.pnpm/@nuxtjs+i18n@9.3.2_@vue+compiler-dom@3.5.13_eslint@9.16.0_jiti@2.4.2__magicast@0.3.5_rollup@4_7mi3yc2p6pmppnrbcyepylts6q/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_kKP56UBlTF from "/home/runner/work/onenews/onenews/web-services/node_modules/.pnpm/@nuxtjs+i18n@9.3.2_@vue+compiler-dom@3.5.13_eslint@9.16.0_jiti@2.4.2__magicast@0.3.5_rollup@4_7mi3yc2p6pmppnrbcyepylts6q/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_0drDzup0eu from "/home/runner/work/onenews/onenews/web-services/node_modules/.pnpm/@nuxtjs+i18n@9.3.2_@vue+compiler-dom@3.5.13_eslint@9.16.0_jiti@2.4.2__magicast@0.3.5_rollup@4_7mi3yc2p6pmppnrbcyepylts6q/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import click_outside_directive_rJYPhIjJqM from "/home/runner/work/onenews/onenews/web-services/common/plugins/click-outside-directive.ts";
import dompurify_html_oZp8P8rx6k from "/home/runner/work/onenews/onenews/web-services/common/plugins/dompurify-html.ts";
import google_tag_manager_client_VkpfDJcmsP from "/home/runner/work/onenews/onenews/web-services/common/plugins/google-tag-manager.client.ts";
import autofocus_when_window_is_active_client_0hdLlrwm4U from "/home/runner/work/onenews/onenews/web-services/onenews/plugins/autofocus-when-window-is-active.client.ts";
import persistedstate_client_O5MsxXlNtZ from "/home/runner/work/onenews/onenews/web-services/onenews/plugins/persistedstate.client.ts";
import ssg_detect_H7ui2x4ffY from "/home/runner/work/onenews/onenews/web-services/node_modules/.pnpm/@nuxtjs+i18n@9.3.2_@vue+compiler-dom@3.5.13_eslint@9.16.0_jiti@2.4.2__magicast@0.3.5_rollup@4_7mi3yc2p6pmppnrbcyepylts6q/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_2dH2OdOIw9,
  unhead_qH8aTiwQAt,
  router_2Tlu0iQSPw,
  payload_client_3sbkFHXPUC,
  navigation_repaint_client_3HBBeXYGiT,
  view_transitions_client_iuLgcIDv9H,
  chunk_reload_client_J2rnWQK0jK,
  plugin_vue3_u2Of28uQtQ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5JXhyzEIC1,
  plugin_client_XvVKEavBaC,
  plugin_NsKj8exyR8,
  slideovers_f9uqWAH9y0,
  modals_EfCSfR5a3a,
  colors_6Znh8khcbD,
  plugin_client_DXVibP6oIh,
  switch_locale_path_ssr_EIiUoMGLeQ,
  route_locale_detect_kKP56UBlTF,
  i18n_0drDzup0eu,
  click_outside_directive_rJYPhIjJqM,
  dompurify_html_oZp8P8rx6k,
  google_tag_manager_client_VkpfDJcmsP,
  autofocus_when_window_is_active_client_0hdLlrwm4U,
  persistedstate_client_O5MsxXlNtZ,
  ssg_detect_H7ui2x4ffY
]