export const getTypeTag = () => {
  const url = new URL(location.href)
  const params = new Proxy(
    new URLSearchParams(decodeURIComponent(url.search)),
    {
      get: (searchParams, prop) => searchParams.get(prop),
    }
  )
  const type = params.type || '0_1000_100_1000_100_691231'
  return type
}
