import { useLocalStorage, createSharedComposable } from '@vueuse/core'
import { useAnalytics } from './analytics/useAnalytics'

interface Setting {
  isEnabled: boolean
  showSettingsToggle: boolean
  isUserSelected: boolean
}

interface Settings {
  articleSummary: Setting
}

export const useUserSettings = createSharedComposable(
  (featureFlags?: FeatureFlags) => {
    const settings = useLocalStorage<Settings>('app:userSettings', {
      articleSummary: {
        isEnabled: true,
        showSettingsToggle: false,
        isUserSelected: false,
      },
    })

    const featureFlagSettings = featureFlags?.userSettings
    if (featureFlagSettings) {
      if (!settings.value.articleSummary.isUserSelected) {
        settings.value.articleSummary.isEnabled =
          featureFlagSettings.defaultSettings.articleSummary
      }
      settings.value.articleSummary.showSettingsToggle =
        featureFlagSettings.enabledSettings.articleSummary
    }

    const updateArticleSummary = (isEnabled: boolean) => {
      const { track } = useAnalytics()

      settings.value.articleSummary.isUserSelected = true
      settings.value.articleSummary.isEnabled = isEnabled

      track('NtpSetting_Changed', {
        setting_name: 'ntp_article_summary',
        setting_value: isEnabled ? 'on' : 'off',
      })
    }

    return {
      articleSummary: settings.value.articleSummary,
      updateArticleSummary,
    }
  }
)
