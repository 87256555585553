import { onMounted } from 'vue'
import useOutbrainAds from '../../composables/ad-ops/useOutbrainAds'
import type { OutbrainProps } from '../../components/AdOps/Outbrain.vue'

interface Slot {
  getSlotElementId: () => string
}

interface Event {
  slot: Slot
  isEmpty: boolean
  slotContentChanged: boolean
  isBackfill: boolean
}

export function setupNativeAdAsFallback() {
  function renderNativeAd(placementElement: Element, placementClass: string) {
    try {
      if (!placementElement) {
        console.error(
          'Element not found for native ad fallback',
          placementClass
        )
        return
      }

      if (placementElement && placementElement instanceof HTMLElement) {
        const { renderFallbackOutbrainAd } = useOutbrainAds()

        renderFallbackOutbrainAd(placementClass, placementElement)
      }
    } catch (err) {
      console.error(
        'Error on Gtag slotRenderEnded listener for native ad fallback',
        err
      )
    }
  }

  function renderNativeAdFallback(event: Event) {
    const elementId = event.slot.getSlotElementId() // Element id for Adx script
    const placementElement = document.getElementById(elementId)?.parentElement
    const placementClass = elementId.replace(/__ayManagerEnv__.+$/, '') // Strip away "__ayManagerEnv__XX..." suffix off the element ID to get the placement class

    if (event.isEmpty && placementElement) {
      renderNativeAd(placementElement, placementClass)
    }
  }

  function addEventListener() {
    if (!import.meta.client) return

    try {
      window.googletag = window.googletag || { cmd: [] }
      window.googletag.cmd.push(function () {
        window.googletag
          .pubads()
          // Watch all programmatic ad slots and fall back to an native ad in case they return empty.
          .addEventListener('slotRenderEnded', renderNativeAdFallback)
      })
    } catch (err) {
      console.error('Error on mount ad functions listeners', err)
    }
  }

  function removeEventListener() {
    window.googletag
      .pubads()
      .removeEventListener('slotRenderEnded', renderNativeAdFallback)
  }

  onMounted(addEventListener)
  onUnmounted(removeEventListener)
}

export function getFallbackPlacementWidget(
  placement: string
): OutbrainProps | undefined {
  const widgets = useAppConfig().outbrain?.widgets

  if (!widgets) {
    console.error('No outbrain widgets found')
    return
  }

  if (!widgets || !widgets[placement]) {
    console.error('No outbrain widget found for: ', placement)
    return
  }

  return widgets[placement]
}
