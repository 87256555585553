import type { DirectiveHook } from 'vue'

const waitForEventLoop = (fn?: () => unknown) => {
  if (fn !== undefined) {
    setTimeout(fn, 100)
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  const handleVisibilityChange = (
    el: Parameters<DirectiveHook<HTMLElement>>[0]
  ) => {
    if (document?.visibilityState === 'visible') {
      el.focus()
    }
  }

  nuxtApp.vueApp.directive('autofocus-on-document-visible', {
    mounted: (el, binding) => {
      el._visibilityChangeEvent = () => handleVisibilityChange(el)
      document.addEventListener('visibilitychange', el._visibilityChangeEvent)

      // TODO: Remove this when Searchbox.vue is removed RACE CONDITION DUE TO DOM APIs
      waitForEventLoop(binding?.value)

      el.focus()
    },
    unmounted: (el) => {
      document.removeEventListener(
        'visibilitychange',
        el._visibilityChangeEvent
      )
      delete el._visibilityChangeEvent
    },
  })
})
